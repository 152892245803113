// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-section_bgimage__FepJK {
  /* width: 100vw;
  height: 100%; */
  /* top: 6%; */
  position: absolute;
  right: 0;
  z-index: -1;
}

.nav-section_wallButtonsC__wH7Cg {
  max-height: 70px;
  margin-top: -10px;
}
.nav-section_wallButtonsC__wH7Cg:focus-visible,
.nav-section_wallButtonsC__wH7Cg:hover,
.nav-section_wallButtonsC__wH7Cg:focus,
.nav-section_wallButtonsC__wH7Cg:active {
  outline: none !important;
  border: none !important;
}
.nav-section_wallButtonsD__ztQEA {
  max-height: 70px;
  margin-top: -9px;
}
.nav-section_wallButtonsD__ztQEA:focus-visible,
.nav-section_wallButtonsD__ztQEA:hover,
.nav-section_wallButtonsD__ztQEA:focus,
.nav-section_wallButtonsD__ztQEA:active {
  outline: none !important;
  border: none !important;
}

.nav-section_logo__kUaig {
  max-width: 50px;
  max-height: 50px;
}
.nav-section_logo__kUaig:focus-visible,
.nav-section_logo__kUaig:hover,
.nav-section_logo__kUaig:focus,
.nav-section_logo__kUaig:active {
  outline: none !important;
  border: none !important;
}

.nav-section_trustedIcon__QqYB5 {
  transition: all 0.2s !important;
  min-width: 60px;
  height: 50px;
  border: none;
}
.nav-section_trustedIcon__QqYB5:hover {
  transform: scale(0.94);
}
@media (max-width: 576px) {
  .nav-section_navcontainer__-d\\+m1 {
    flex-wrap: nowrap !important;
  }
  .nav-section_navBtn__7smeq{
    min-width: 110.87px !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/nav-section/index.module.css"],"names":[],"mappings":"AAAA;EACE;iBACe;EACf,aAAa;EACb,kBAAkB;EAClB,QAAQ;EACR,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;AACnB;AACA;;;;EAIE,wBAAwB;EACxB,uBAAuB;AACzB;AACA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;AACA;;;;EAIE,wBAAwB;EACxB,uBAAuB;AACzB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;AACA;;;;EAIE,wBAAwB;EACxB,uBAAuB;AACzB;;AAEA;EACE,+BAA+B;EAC/B,eAAe;EACf,YAAY;EACZ,YAAY;AACd;AACA;EACE,sBAAsB;AACxB;AACA;EACE;IACE,4BAA4B;EAC9B;EACA;IACE,8BAA8B;EAChC;AACF","sourcesContent":[".bgimage {\n  /* width: 100vw;\n  height: 100%; */\n  /* top: 6%; */\n  position: absolute;\n  right: 0;\n  z-index: -1;\n}\n\n.wallButtonsC {\n  max-height: 70px;\n  margin-top: -10px;\n}\n.wallButtonsC:focus-visible,\n.wallButtonsC:hover,\n.wallButtonsC:focus,\n.wallButtonsC:active {\n  outline: none !important;\n  border: none !important;\n}\n.wallButtonsD {\n  max-height: 70px;\n  margin-top: -9px;\n}\n.wallButtonsD:focus-visible,\n.wallButtonsD:hover,\n.wallButtonsD:focus,\n.wallButtonsD:active {\n  outline: none !important;\n  border: none !important;\n}\n\n.logo {\n  max-width: 50px;\n  max-height: 50px;\n}\n.logo:focus-visible,\n.logo:hover,\n.logo:focus,\n.logo:active {\n  outline: none !important;\n  border: none !important;\n}\n\n.trustedIcon {\n  transition: all 0.2s !important;\n  min-width: 60px;\n  height: 50px;\n  border: none;\n}\n.trustedIcon:hover {\n  transform: scale(0.94);\n}\n@media (max-width: 576px) {\n  .navcontainer {\n    flex-wrap: nowrap !important;\n  }\n  .navBtn{\n    min-width: 110.87px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bgimage": `nav-section_bgimage__FepJK`,
	"wallButtonsC": `nav-section_wallButtonsC__wH7Cg`,
	"wallButtonsD": `nav-section_wallButtonsD__ztQEA`,
	"logo": `nav-section_logo__kUaig`,
	"trustedIcon": `nav-section_trustedIcon__QqYB5`,
	"navcontainer": `nav-section_navcontainer__-d+m1`,
	"navBtn": `nav-section_navBtn__7smeq`
};
export default ___CSS_LOADER_EXPORT___;
