import "./App.css";
import { Route, Routes } from "react-router-dom";
import Dashboard from "./pages/user-dashboard";
import Navbar from "./components/nav-section";
import { PolkadotProvider } from "./context/polkadotContext";
import StakingDashboard from "./pages/user-dashboard/dashboards/staking_dashboard";
import { TaoDetailsProvider } from "./context/taoDetailsContext";
import Subnets from "./pages/subnets";
import ProxyDashboard from "./pages/user-dashboard/dashboards/proxy_dashboard";

function App() {
  return (
    <div className="App">
      <PolkadotProvider>
        <TaoDetailsProvider>
          <Navbar />
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/staking" element={<StakingDashboard />} />
            <Route path="/subnets" element={<Subnets />} />
            <Route path="/delegates" element={<ProxyDashboard />} />
          </Routes>
        </TaoDetailsProvider>
      </PolkadotProvider>
    </div>
  );
}

export default App;
