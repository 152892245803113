import axios from "axios";
import { getOrderedItem } from "./orderItem";
import { taoData } from "./taoDetails";

const trustedstakeURL = `${process.env.REACT_APP_TRUSTEDSTAKE_URL}`;
const taourl = `${process.env.REACT_APP_COINGECKO_URL}`;
const api_key = `${process.env.REACT_APP_API_KEY}`;

export type meta = {
  netuid: number;
  network: string;
};

export const getMetagraph = async (req: meta) => {
  try {
    const res = await axios.get(`${trustedstakeURL}/metagraph`, {
      params: req,
    });
    return res;
  } catch (error) {
    console.log("🚀 ~ getMetagraph ~ error:", error);
    throw error;
  }
};

export const getSubnetList = async () => {
  try {
    const res = await axios.get(`${trustedstakeURL}/subnetList`);
    const ordered_data = res.data?.map((val: any) => {
      return getOrderedItem(val);
    });

    return ordered_data;
  } catch (error) {
    console.log("🚀 ~ getSubnetList ~ error:", error);
    throw error;
  }
};

export const getTAODetails = async () => {
  try {
    const res = await axios.get(
      `${taourl}?localization=false&tickers=false&market_data=true&community_data=false&developer_data=false`,
      {
        headers: {
          accept: "application/json",
          api_key,
        },
      }
    );
    let data = taoData(res.data.market_data);
    return data;
  } catch (error) {
    console.log("🚀 ~ getSubnetList ~ error:", error);
    throw error;
  }
};

export const getHistoricalPriceData = async (days: number) => {
  try {
    const res = await axios.get(
      `${taourl}/market_chart?vs_currency=usd&days=${days}`,
      {
        headers: {
          accept: "application/json",
          api_key,
        },
      }
    );
    return res.data;
  } catch (error) {
    console.log("🚀 ~ getSubnetList ~ error:", error);
    throw error;
  }
};
