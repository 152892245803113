import React, { useContext, useEffect, useRef } from "react";
import styles from "./wallet.module.css";
import { PolkadotContext } from "../context/polkadotContext";

interface WalletModalProps {
  isOpen: boolean;
  close: () => void;
}

const WalletModal: React.FC<WalletModalProps> = ({ isOpen, close }) => {
  const { enableExtension, isConnected } =
    useContext(PolkadotContext);
  const modalRef = useRef<HTMLDivElement>(null);

  const handleWallet = () => {
    enableExtension();
    close()
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        close();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, close]);

  return (
    <>
      {!isConnected && (
        <div
          className={`modal modal-sm modal-dark fade ${isOpen ? "show" : ""}`}
          style={{ display: isOpen ? "block" : "none" }}
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden={true}
        >
          <div className={`modal-dialog ${styles.modaldialog}`} ref={modalRef}>
            <div className={`modal-content w-md-75 ${styles.modalcontent}`}>
              <>
                <div className="modal-header d-block text-center">
                  <h5 className="modal-title" id="exampleModalLabel">
                    TAO Management
                  </h5>
                </div>
                <div className="modal-body">
                  <button
                    type="button"
                    className={`btn btn-dark text-light fw-medium trustedButton`}
                    onClick={() => handleWallet()}
                  >
                    Connect Wallet
                  </button>
                  <div className="pt-3">
                    <p className="m-0 fs-6 fw-light">
                      Install the{" "}
                      <a
                        href="https://bittensor.com/wallet/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Bittensor
                      </a>{" "}
                      /{" "}
                      <a
                        href="https://polkadot.js.org/extension/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Polkadot
                      </a>{" "}
                      extensions
                    </p>
                    <p className="m-0 fs-6 fw-light">or</p>
                    <p className="m-0 fs-6 fw-light">
                      <a
                        href="https://docs.novawallet.io/nova-wallet-wiki/dapps/connect-to-dapps"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="underline text-primary"
                      >
                        Connect
                      </a>{" "}
                      your{" "}
                      <a
                        href="https://novawallet.io/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="underline text-primary"
                      >
                        Nova Wallet
                      </a>{" "}
                      (mobile)
                    </p>
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default WalletModal;
