import React, { createContext, useState, ReactNode, useEffect } from "react";
import { getSubnetList, getTAODetails } from "../api/api";

interface TaoContextType {
  taoDetails: any;
  subnetList: any;
  getTaoData: () => Promise<void>;
  getSubnets: () => Promise<void>;
}

const defaultContextValue: TaoContextType = {
  taoDetails: {},
  subnetList: {},
  getTaoData: async () => {},
  getSubnets: async () => {},
};

export const TaoContext = createContext<TaoContextType>(defaultContextValue);

interface TaoProviderProps {
  children: ReactNode;
}

export const TaoDetailsProvider: React.FC<TaoProviderProps> = ({
  children,
}) => {
  const [taoDetails, setTaoDetails] = useState<any>({});
  const [subnetList, setSubnetList] = useState<any>({});

  const getTaoData = async () => {
    try {
      const data = await getTAODetails();
      setTaoDetails(data);
    } catch (error) {
      console.log("🚀 ~ getTaoData ~ error:", error);
    }
  };

  const getSubnets = async () => {
    try {
      const data = await getSubnetList();
      setSubnetList(data);
    } catch (error) {
      console.log("🚀 ~ getSubnets ~ error:", error);
    }
  };

  useEffect(() => {
    getTaoData();
    getSubnets();
  }, []);

  return (
    <TaoContext.Provider
      value={{
        taoDetails,
        subnetList,
        getTaoData,
        getSubnets,
      }}
    >
      {children}
    </TaoContext.Provider>
  );
};
