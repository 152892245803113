import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  ChartData,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
} from "chart.js";

ChartJS.register(
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
  Title,
  Legend
);

interface coordinates {
  inputArray: [number[], number[], number[]];
}

const PriceHistory: React.FC<coordinates> = ({ inputArray }) => {
  const dates = inputArray[0].map((item) => new Date(item));
  const m_caps = inputArray[2].map((item) => item);
  const [dataZwei, setDataZwei] = useState<ChartData<"line">>({
    labels: dates.map((date) => date.toLocaleString()),
    datasets: [
      {
        // label: "Price Over Time",
        fill: true,
        backgroundColor: "black",
        borderColor: "black",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "black",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 6,
        pointHoverRadius: 5,
        // pointHoverBackgroundColor: "black",
        // pointHoverBorderColor: "black",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 20,
        data: m_caps,
      },
      {
        // label: "Price Over Time",
        fill: true,
        backgroundColor: "green",
        borderColor: "green",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "green",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 6,
        pointHoverRadius: 5,
        // pointHoverBackgroundColor: "black",
        // pointHoverBorderColor: "black",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 20,
        data: m_caps,
      },
    ],
  });

  const options = {
    scales: {
      x: {
        ticks: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
      y: {
        ticks: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      tooltip: {
        enabled: true,
        callbacks: {
          label: (tooltipItem: any) => {
            return `Price: ${tooltipItem.raw}`;
          },
        },
      },
    },
  };

  useEffect(() => {
    // setChart(<Line data={dataZwei} options={options} />);
    const dates = inputArray[0].map((item) => new Date(item));
    const values = inputArray[1].map((item) => item);
    setDataZwei({
      labels: dates.map((date) => date.toLocaleString()),
      datasets: [
        {
          label: "Price Over Time",
          fill: false,
          backgroundColor: "black",
          borderColor: "black",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "black",
          pointBackgroundColor: "#fff",
          // pointBorderWidth: 1,
          // pointHoverRadius: 5,
          // pointHoverBackgroundColor: "black",
          // pointHoverBorderColor: "black",
          // pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 20,
          data: values,
        },
        // {
        //   // label: "Price Over Time",
        //   fill: true,
        //   backgroundColor: "green",
        //   borderColor: "green",
        //   borderCapStyle: "butt",
        //   borderDash: [],
        //   borderDashOffset: 0.0,
        //   borderJoinStyle: "miter",
        //   pointBorderColor: "green",
        //   pointBackgroundColor: "#fff",
        //   pointBorderWidth: 6,
        //   pointHoverRadius: 5,
        //   // pointHoverBackgroundColor: "black",
        //   // pointHoverBorderColor: "black",
        //   pointHoverBorderWidth: 2,
        //   pointRadius: 1,
        //   pointHitRadius: 20,
        //   data: m_caps,
        // },
      ],
    });
  }, [inputArray]);

  return (
    <Row>
      <Col>
        <Line data={dataZwei} options={options} />
      </Col>
    </Row>
  );
};

export default PriceHistory;
