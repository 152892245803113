import { useContext, useEffect, useState } from "react";
import TaoInfo from "./taoInfo";
import { TaoContext } from "../../context/taoDetailsContext";
import SubnetTable from "../../components/list/subnetList";
import styles from "./index.module.css";
import UserInfo from "./userInfo";
import { Link } from "react-router-dom";
import PriceHistory from "../../utility/charts/priceHistory";
import { priceHistoryChart } from "../../api/priceHistory";
import TextGalaxy from "../../components/animation/galaxy";

function Dashboard() {
  const { taoDetails, subnetList } = useContext(TaoContext);
  const [pricedata, setPricedata] = useState<[number[], number[], number[]]>([
    [0],
    [0],
    [0],
  ]);
  const [days, setDays] = useState(7);

  useEffect(() => {
    const getData = async () => {
      try {
        let c = await priceHistoryChart(days);
        setPricedata([c.arr1, c.arr2, c.arr3]);
      } catch (error) {
        console.log("🚀 ~ useEffect ~ error:", error);
      }
    };
    getData();
  }, [days]);

  const handleTime = async (days: number) => {
    console.log(days);
    setDays(days);
  };

  return (
    <>
      <div className="">
        {/* <TextGalaxy /> */}
      </div>
      <div className="">
        <div className="container mt-5 z-2">
          <UserInfo />
        </div>
      </div>
      <div className="container mt-5">
        <div className="mt-5">
          {Object.keys(taoDetails).length !== 0 && (
            <TaoInfo taoDetails={taoDetails} />
          )}

          <p className="heading1 text-start mt-5">Price Chart</p>
          <div
            className="btn-toolbar"
            role="toolbar"
            aria-label="Toolbar with button groups"
          >
            <div
              className="btn-group me-2 ps-5"
              role="group"
              aria-label="First group"
            >
              <button
                type="button"
                className={`btn ${days === 1 ? "active text-light" : ""} ${
                  styles.drpButt
                } btn-outline-dark`}
                onClick={() => handleTime(1)}
              >
                24h
              </button>
              <button
                type="button"
                className={`btn ${days === 7 ? "active text-light" : ""} ${
                  styles.drpButt
                } btn-outline-dark`}
                onClick={() => handleTime(7)}
              >
                7 days
              </button>
              <button
                type="button"
                className={`btn ${days === 30 ? "active text-light" : ""} ${
                  styles.drpButt
                }  btn-outline-dark`}
                onClick={() => handleTime(30)}
              >
                30 days
              </button>
            </div>
          </div>
          <div className="pb-5 px-5">
            {<PriceHistory inputArray={pricedata} />}
          </div>

          {Object.keys(subnetList).length !== 0 ? (
            <>
              <p className="heading1 text-start mt-5">
                Bittensor Tranding Subnets
              </p>
              <div className="container">
                <SubnetTable data={subnetList} rowsToShow={5} />
                <div className="p-3">
                  <Link to={"/subnets"}>
                    <button
                      type="button"
                      className={`btn w-25 btn-dark fw-medium trustedButton`}
                    >
                      Click To See All Subnets
                    </button>
                  </Link>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}

export default Dashboard;
