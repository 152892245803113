// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wallet_walletmodal__qM5C7 {
  margin-right: 85px !important;
  top: 3rem;
}
.wallet_walletBut__7o9U8 {
  width: -webkit-fill-available;
}
.wallet_modalcontent__JYLwS {
  background: black !important;
  color: white !important;
}
.wallet_logo__zN-gU {
  width: 50px;
}

.wallet_modaldialog__OymhK {
  top: 60px;

  @media (min-width: 768px) {
    left: 90px;
  }
  @media (min-width: 992px) {
    left: 210px;
  }
  @media (min-width: 1200px) {
    left: 300px;
  }
  @media (min-width: 1400px) {
    left: 385px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/utility/wallet.module.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,SAAS;AACX;AACA;EACE,6BAA6B;AAC/B;AACA;EACE,4BAA4B;EAC5B,uBAAuB;AACzB;AACA;EACE,WAAW;AACb;;AAEA;EACE,SAAS;;EAET;IACE,UAAU;EACZ;EACA;IACE,WAAW;EACb;EACA;IACE,WAAW;EACb;EACA;IACE,WAAW;EACb;AACF","sourcesContent":[".walletmodal {\n  margin-right: 85px !important;\n  top: 3rem;\n}\n.walletBut {\n  width: -webkit-fill-available;\n}\n.modalcontent {\n  background: black !important;\n  color: white !important;\n}\n.logo {\n  width: 50px;\n}\n\n.modaldialog {\n  top: 60px;\n\n  @media (min-width: 768px) {\n    left: 90px;\n  }\n  @media (min-width: 992px) {\n    left: 210px;\n  }\n  @media (min-width: 1200px) {\n    left: 300px;\n  }\n  @media (min-width: 1400px) {\n    left: 385px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"walletmodal": `wallet_walletmodal__qM5C7`,
	"walletBut": `wallet_walletBut__7o9U8`,
	"modalcontent": `wallet_modalcontent__JYLwS`,
	"logo": `wallet_logo__zN-gU`,
	"modaldialog": `wallet_modaldialog__OymhK`
};
export default ___CSS_LOADER_EXPORT___;
