export const taoData = (data: any) => {
  const t_info = {
    id: "bittensor",
    symbol: "tao",
    name: "Bittensor",
    image:
      "https://coin-images.coingecko.com/coins/images/28452/large/ARUsPeNQ_400x400.jpeg?1696527447",
    current_price: "$" + formatValue(data.current_price.usd),
    market_cap: formatValue(data.market_cap.usd),
    market_cap_rank: data.market_cap_rank,
    fully_diluted_valuation: formatValue(data.fully_diluted_valuation.usd),
    total_volume: formatValue(data.total_volume.usd),
    high_24h: formatValue(data.high_24h.usd),
    low_24h: formatValue(data.low_24h.usd),
    price_change_24h: formatValue(data.price_change_24h),
    price_change_percentage_24h:
      formatValue(data.price_change_percentage_24h),
    market_cap_change_24h: formatValue(data.market_cap_change_24h),
    market_cap_change_percentage_24h:
      data.market_cap_change_percentage_24h,
    circulating_supply: formatValue(data.circulating_supply),
    total_supply: formatValue(data.total_supply),
    max_supply: formatValue(data.max_supply),
    ath: formatValue(data.ath.usd),
    ath_change_percentage: formatValue(data.ath_change_percentage.usd),
    ath_date: formatValue(data.ath_date.usd),
    atl: formatValue(data.atl.usd),
    atl_change_percentage: data.atl_change_percentage.usd,
    atl_date: formatValue(data.atl_date.usd),
    roi: data.roi,
    last_updated: data.last_updated,
  };
  return t_info;
};

function formatValue(value: number | null | undefined) {
  if (value === null || value === undefined || isNaN(value)) {
    return "-"; // Handle invalid values
  }

  const absValue = Math.abs(value);
  let formattedValue = "";

  if (absValue >= 1.0e9) {
    formattedValue = (value / 1.0e9).toFixed(2) + "B";
  } else if (absValue >= 1.0e6) {
    formattedValue = (value / 1.0e6).toFixed(2) + "M";
  } else if (absValue >= 1.0e3) {
    formattedValue = (value / 1.0e3).toFixed(2) + "K";
  } else {
    formattedValue = value.toFixed(2);
  }

  return formattedValue;
}
