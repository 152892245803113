import { useContext, useState } from "react";
import styles from "./index.module.css";
import { Link } from "react-router-dom";
import { PolkadotContext } from "../../context/polkadotContext";
import WalletModal from "../../utility/walletModal";

function Navbar() {
  const { isConnected, disconnect } = useContext(PolkadotContext);
  const [isWalletOpen, setIsWalletOpen] = useState(false);
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className={`container align-content-between ${styles.navcontainer} `}>
          <div>
            <Link to={"/"}>
              <img
                className={styles.logo}
                src="/assets/trustedstakelogo.jpg"
                alt="trustedstack"
              />
            </Link>
          </div>
          <div>
            <div className="d-flex justify-content-around gap-1">
              <button
                type="button"
                className={`btn ${styles.trustedIcon} border border-0 p-0`}
                onClick={() =>
                  isConnected ? disconnect() : setIsWalletOpen(true)
                }
              >
                <img
                  className={
                    isConnected
                      ? `${styles.wallButtonsD}`
                      : `${styles.wallButtonsC}`
                  }
                  src={
                    isConnected ? "assets/disconnect.png" : "assets/connect.png"
                  }
                  alt="connect"
                />
              </button>
              <Link to={"/"}>
                <button
                  type="button"
                  className={`btn btn-dark text-light fw-medium trustedButton ${styles.navBtn}`}
                >
                  Home
                </button>
              </Link>
              {/* <Link to={"/staking"}>
                <button
                  type="button"
                  className={`btn btn-dark text-light fw-medium trustedButton`}
                >
                  Stake
                </button>
              </Link> */}
              <Link to={"/delegates"}>
                <button
                  type="button"
                  className={`btn btn-dark text-light fw-medium trustedButton ${styles.navBtn}`}
                >
                  Delegate
                </button>
              </Link>
            </div>
          </div>
        </div>
        <WalletModal
          isOpen={isWalletOpen}
          close={() => setIsWalletOpen(!isWalletOpen)}
        />
      </nav>
      {/* <TextGalaxy /> */}
    </>
  );  
}

export default Navbar;
