import { getHistoricalPriceData } from "./api";

export const priceHistoryChart = async (days: number) => {
  let result = await getHistoricalPriceData(days);
  let new_result = formatPrice(result.prices, result.market_caps);
  return new_result;
};

const formatPrice = (
  priceArr: [number, number][],
  marketArr: [number, number][]
) => {
  const arr1: number[] = [];
  const arr2: number[] = [];
  const arr3: number[] = [];

  priceArr.forEach((item) => {
    arr1.push(item[0]);
    arr2.push(parseFloat(item[1].toFixed(2)));
  });
  marketArr.forEach((item) => {
    arr3.push(parseFloat((item[1] / 10000000).toFixed(2)));
  });

  return { arr1, arr2, arr3 };
};
