export const getOrderedItem = (item: any) => {
  const n_item = {
    UID: item.NetUID,
    Owner: shorten(item.Owner),
    Emission: parseFloat((item.Emission / 1000000000).toFixed(5)),
    Burn: item.Burn,
    Rho: item.rho,
    Subnetwork_n: item.subnetwork_n,
  };
  return n_item;
};

const shorten = (fullStr: string) => {
  if (fullStr.length <= 10) {
    return fullStr;
  }
  const start = fullStr.substring(0, 5);
  const end = fullStr.substring(fullStr.length - 5);
  return `${start}...${end}`;
};
