import axios from "axios";
import { checkIfDelegated } from "./delegate";

const baseURL = `${process.env.REACT_APP_BASE_URL}`;
const target = `${process.env.REACT_APP_PROXY_ADDRESS}`;

export type meta = {
  netuid: number;
  network: string;
};

export const getUserData = async (userAddress: string) => {
  try {
    const res = await axios.get(`${baseURL}/user-details/${userAddress}`);
    if (res.status == 200) {
      return res.data;
    }
  } catch (error) {
    console.log("🚀 ~ getSubnetList ~ error:", error);
    throw error;
  }
};

export const createUser = async (
  walletAddress: string,
  Balance: number,
  is_delegated: boolean,
  isActive: boolean
): Promise<boolean> => {
  try {
    const response = await axios.post<boolean>(`${baseURL}/user-details`, {
      wallet_address: walletAddress,
      balance: Balance,
      delegate_date_time: new Date(),
      stake_balance: 0.0,
      profit_loss: 0.0,
      staking_date_time: null,
      created_date: new Date(),
      is_active: isActive,
      is_delegated: is_delegated,
    });
    return await response.data;
  } catch (error: any) {
    console.error("Create user failed:", error);

    return false;
  }
};

export const updateUser = async (
  wallet_address: string,
  balance: number,
  stake_balance: number,
  profit_loss: number,
  is_active: boolean,
  is_delegated: boolean
) => {
  try {
    if (
      (await checkIfDelegated(wallet_address, target)).isDelegated ===
      is_delegated
    ) {
      const response = await axios.put(
        `${baseURL}/user-details/${wallet_address}`,
        {
          wallet_address: wallet_address,
          balance: balance,
          stake_balance: stake_balance,
          profit_loss: profit_loss,
          is_active: is_active,
          is_delegated: is_delegated,
        }
      );
      return response;
    }
  } catch (error) {
    console.log("🚀 ~ error:", error);
  }
};
