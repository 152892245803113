import UserInfo from "../userInfo";

function ProxyDashboard() {
  return (
    <div className="container mt-5">
      <div className="row justify-content-center align-items-center g-2">
        <UserInfo />
      </div>
    </div>
  );
}

export default ProxyDashboard;
