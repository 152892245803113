// src/PolkadotContext.tsx
import React, { createContext, useState, ReactNode } from "react";
import { InjectedAccountWithMeta } from "@polkadot/extension-inject/types";
import { web3Enable, web3Accounts } from "@polkadot/extension-dapp";
import api from "../wallet/polkadotApi";
import { checkIfDelegated } from "../api/delegate";

interface WalletOverview {
  free: number;
  frozen: number;
  reserved: number;
}
interface userDetailsType {
  name: string;
  address: string;
}

interface PolkadotContextType {
  isDelegated: boolean;
  accounts: InjectedAccountWithMeta[];
  selectedAccount: userDetailsType;
  enableExtension: () => Promise<void>;
  isConnected: boolean;
  disconnect: () => void;
  setdelegate: (delegate: boolean) => void;
  setMainAccount: (address: string, name: string) => void;
  setUserBalance: (address: string) => Promise<void>;
  walletOverview: WalletOverview;
}

const defaultContextValue: PolkadotContextType = {
  isDelegated: false,
  accounts: [],
  selectedAccount: { name: "", address: "" },
  enableExtension: async () => {},
  isConnected: false,
  disconnect: () => {},
  setdelegate: () => {},
  setMainAccount: () => {},
  setUserBalance: async () => {},
  walletOverview: {
    free: 0,
    frozen: 0,
    reserved: 0,
  },
};

export const PolkadotContext =
  createContext<PolkadotContextType>(defaultContextValue);

interface PolkadotProviderProps {
  children: ReactNode;
}

export const PolkadotProvider: React.FC<PolkadotProviderProps> = ({
  children,
}) => {
  const [accounts, setAccounts] = useState<InjectedAccountWithMeta[]>([]);
  const [selectedAccount, setSelecetedAccount] = useState({
    name: "",
    address: "",
  });
  const [isConnect, setIsConnect] = useState(false);
  const [isDelegated, setIsDelegated] = useState(false);
  const [walletOverview, setWalletOverview] = useState<WalletOverview>({
    free: 0,
    frozen: 0,
    reserved: 0,
  });

  const enableExtension = async () => {
    try {
      const extensions = await web3Enable("ame-polkadot-app");
      if (extensions.length === 0) {
        console.log("No Polkadot extension installed");
        return;
      }

      const accounts = await web3Accounts();
      setSelecetedAccount({
        name: accounts[0].meta.name ? accounts[0].meta.name : "",
        address: accounts[0].address,
      });

      // Set accounts
      setAccounts(accounts);
      setIsConnect(true);

      // Parse balances
      let data = JSON.parse(
        (await (await api).query.system.account(accounts[0].address)).toString()
      );
      let w: WalletOverview = {
        free: parseFloat((data.data.free / 1000000000).toFixed(2)),
        frozen: parseFloat((data.data.frozen / 1000000000).toFixed(2)),
        reserved: parseFloat((data.data.reserved / 1000000000).toFixed(2)),
      };
      setWalletOverview(w);

      const isDelegated = await (
        await checkIfDelegated(
          accounts[0].address,
          `${process.env.REACT_APP_PROXY_ADDRESS}`
        )
      ).isDelegated;

      // Check if already delegated
      if (isDelegated) {
        setIsDelegated(true);
      }
    } catch (error) {
      console.error("Error enabling Polkadot extension:", error);
    }
  };

  // Set user balance
  const setUserBalance = async (address: string) => {
    let data = JSON.parse(
      (await (await api).query.system.account(address)).toString()
    );
    let w: WalletOverview = {
      free: parseFloat((data.data.free / 1000000000).toFixed(2)),
      frozen: parseFloat((data.data.frozen / 1000000000).toFixed(2)),
      reserved: parseFloat((data.data.reserved / 1000000000).toFixed(2)),
    };
    setWalletOverview(w);
  };

  // Disconnect the user wallet
  const disconnect = () => {
    setAccounts([]);
    setIsConnect(false);
  };

  // Set as if the wallet address is delegated or not
  const setdelegate = (delegate: boolean) => {
    setIsDelegated(delegate);
  };

  // Set user address from multiple addresses
  const setMainAccount = (address: string, name: string) => {
    setSelecetedAccount({
      name,
      address,
    });
  };

  return (
    <PolkadotContext.Provider
      value={{
        isDelegated,
        accounts,
        selectedAccount,
        enableExtension,
        isConnected: isConnect,
        disconnect,
        setdelegate,
        setMainAccount,
        setUserBalance,
        walletOverview,
      }}
    >
      {children}
    </PolkadotContext.Provider>
  );
};
