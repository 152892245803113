import React, { useEffect, useState } from "react";

interface ToastProps {
  txStatus: boolean;
  message: string;
  onClose: () => void;
}

const TrustedToast: React.FC<ToastProps> = ({ txStatus, message, onClose }) => {
  const [progress, setProgress] = useState<number>(100);

  useEffect(() => {
    const duration = 10000;
    const interval = 30;

    const timer = setTimeout(() => {
      onClose();
    }, duration);

    const progressInterval = setInterval(() => {
      setProgress((prevProgress) =>
        Math.max(prevProgress - 100 / (duration / interval), 0)
      );
    }, interval);

    return () => {
      clearTimeout(timer);
      clearInterval(progressInterval);
    };
  }, [onClose]);

  return (
    <>
      <div className="toast-body">
        <div className="ps-5 text-light fw-bold">
          <p className="m-0">
            {txStatus ? "Successfully delegated" : "Please try again later"}
          </p>
          <p className="m-0">{message}</p>
        </div>
      </div>
      <div
        className="progress mt-2"
        style={{ width: "100%", height: "5px", background: "#444" }}
      >
        <div
          className="progress-bar"
          style={{
            width: `${progress}%`,
            transition: "width 0.03s linear",
            background: "white",
          }}
        />
      </div>
    </>
  );
};

export default TrustedToast;
