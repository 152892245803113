interface KeyValueInfoProps {
  cls: string | any;
  label: string;
  value: React.ReactNode;
}

export const KeyValueInfo: React.FC<KeyValueInfoProps> = ({
  label,
  value,
  cls,
}) => (
  <div className={`${cls}`}>
    <p className="m-0 fs-6 fw-light">{label}</p>
    <p className="m-0 fw-bold text-center">{value}</p>
  </div>
);
