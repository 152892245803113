import React from "react";
import { KeyValueInfo } from "../../utility/keyValueProps";

interface taoInfoProps {
  taoDetails: any;
}

const TaoInfo: React.FC<taoInfoProps> = ({ taoDetails }) => {
  return (
    <>
      <p className="heading1 text-start mt-5">Market Inflow/Outflow</p>
      <div className="row align-items-center justify-content-start bg-white p-1 text-black rounded-1">
        <div className="col-4 d-flex align-items-center justify-content-between">
          <div
            className=""
            style={{
              width: "30%",
              padding: "2px",
              borderRadius: "50%",
              backgroundColor: "black",
            }}
          >
            <img
              className=""
              src={taoDetails.image}
              alt="TAO"
              style={{
                width: "100%",
                borderRadius: "50%",
              }}
            />
          </div>
          <div className="">
            <p className="fw-light fs-5 m-0">Bittensor Price</p>
            <div
              className={`row fs-5 btn-group btn border border-0 d-flex ${
                taoDetails.price_change_percentage_24h > 0
                  ? "dropup"
                  : "dropdown"
              }`}
            >
              <p className="col fw-bold m-0">{taoDetails.current_price}</p>
              <p
                className={`col fw-bold m-0 ${
                  taoDetails.price_change_percentage_24h > 0
                    ? "text-success dropdown-toggle "
                    : "text-danger dropdown-toggle"
                }`}
              >
                {taoDetails.price_change_percentage_24h}%
              </p>
            </div>
          </div>
        </div>
        <div className="col-8 d-flex">
          <KeyValueInfo
            label="Market Cap "
            value={`${taoDetails.market_cap}`}
            cls={"col-3"}
          />
          <KeyValueInfo
            label="24h Volume "
            value={`${taoDetails.total_volume}`}
            cls={"col-3"}
          />
          <KeyValueInfo
            label="Supply "
            value={`${taoDetails.total_supply}`}
            cls={"col-3"}
          />
          <KeyValueInfo
            label="Circulating Supply "
            value={`${taoDetails.circulating_supply}`}
            cls={"col-3"}
          />
        </div>
      </div>
    </>
  );
};

export default TaoInfo;
