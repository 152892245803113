import React from "react";
import { Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
} from "chart.js";
import "bootstrap/dist/css/bootstrap.min.css";

interface values {
  free: number;
  staked: number;
}
// Register the necessary components
ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale);

const UserPortfolio: React.FC<values> = ({ free, staked }) => {
  const freePercent = (free / (free + staked)) * 100 || 0;
  const data = {
    labels: ["Delegated", "Free"],
    datasets: [
      {
        data: [staked, free],
        backgroundColor: ["#CCCCCC", "#666666"],
        hoverBackgroundColor: ["#E0E0E0", "#888888"],
      },
    ],
  };

  const options = {
    cutout: "70%",
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem: any) =>
            `${tooltipItem.label}: ${tooltipItem.raw.toFixed(2)} τ`,
        },
      },
    },
  };

  return (
    <>
      <div className="row align-items-center justify-content-center text-start">
        <div className="col-7">
          <Doughnut data={data} options={options} />
        </div>
        <div className="col-5">
          <div className=" text-light-emphasis">
            <strong>Staked: </strong> ({(100 - freePercent).toFixed(2)}%)
          </div>
          <div className="text-dark-emphasis">
            <strong>Free: </strong> ({freePercent.toFixed(2)}%)
          </div>
        </div>
      </div>
    </>
  );
};

export default UserPortfolio;
