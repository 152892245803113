import Table from "react-bootstrap/Table";

interface subnetListProps {
  data: Array<any>;
  rowsToShow: number;
}
const SubnetTable: React.FC<subnetListProps> = ({ data, rowsToShow }) => {
  const headers = Object.keys(data[0]);

  return (
    <>
      {data ? (
        <div className="">
          <Table borderless hover striped responsive className="text-start boxShadow">
            <thead>
              <tr>
                {headers?.map((val, index) => (
                  <th key={index}>{val}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.slice(0, rowsToShow).map((item, index) => (
                <tr key={index}>
                  {headers?.map((header, i) => (
                    <td key={i} className="">{`${item[header]}`}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default SubnetTable;
