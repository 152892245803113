import { useContext, useEffect, useState } from "react";
import { PolkadotContext } from "../../context/polkadotContext";
import { ShortenString } from "../../utility/decorator/addressDecor";
import styles from "./index.module.css";
import { Table } from "react-bootstrap";
import UserPortfolio from "./userPortfolio";
import { Link, useLocation } from "react-router-dom";
import { checkIfDelegated, delegate, removeDelegate } from "../../api/delegate";
import TrustedToast from "../../utility/trustedToasts";
import { getBalance } from "../../utility/getBalance";
import { getUserData } from "../../api/userbackend";

function UserInfo() {
  const location = useLocation();
  const [showToast, setShowToast] = useState<boolean>(false);
  const [message, setMessage] = useState<string | null>(null);
  const [txStatus, setTxStatus] = useState(false);
  const [stakedAmt, setStakedAmt] = useState(0);
  const [freeBalance, setfreeBalance] = useState(0);
  const [delegateLoading, setDelegateLoading] = useState(false);
  const [unDelegateloading, setUnDelegateloading] = useState(false);
  const [error, setError] = useState<Error | null>(null); // State to handle errors
  const {
    isDelegated,
    setdelegate,
    enableExtension,
    accounts,
    selectedAccount,
    setMainAccount,
    setUserBalance,
    isConnected,
    walletOverview,
  } = useContext(PolkadotContext);
  const target = `${process.env.REACT_APP_PROXY_ADDRESS}`;

  const handleUndelegation = async () => {
    setUnDelegateloading(true);
    try {
      const result = await removeDelegate(selectedAccount.address, accounts);
      if (result.status) {
        setdelegate(false);
      }

      if (result.status) {
        setdelegate(false);
        setMessage(`${result.message}`);
        console.log("Undelegate transaction successful:", result.message);
      } else {
        setMessage(`${result.message}`);
        console.error("Undelegate transaction failed:", result.message);
      }

      setTxStatus(result.status);
      setShowToast(true);
    } catch (error) {
      console.error("Error in undelegation:", error);
      setMessage("An error occurred during the undelegation process.");
    } finally {
      setUnDelegateloading(false);
    }
  };

  useEffect(() => {
    const userDetails = async () => {
      try {
        const data = await getUserData(selectedAccount.address);
        console.log("🚀 ~ userDetails ~ data:", data);
        const st_balance = parseFloat(
          parseFloat(data.stake_balance).toFixed(2)
        );
        if (!data) {
          throw new Error("Network response was not ok");
        }
        setStakedAmt(st_balance);
        setfreeBalance(
          parseFloat(
            (
              walletOverview.free +
              walletOverview.frozen +
              walletOverview.reserved
            ).toFixed(2)
          )
        );
        console.log("🚀 ~ userDetails ~ b:", st_balance);
      } catch (error) {
        // Ensure error is typed as Error before setting it
        if (error instanceof Error) {
          setError(error); // Handle errors
        } else {
          setError(new Error("An unexpected error occurred")); // Handle non-Error exceptions
        }
      }
    };

    userDetails();
  }, [selectedAccount]);

  const handleDelegation = async () => {
    setDelegateLoading(true);

    try {
      if (!isDelegated) {
        const result = await delegate(selectedAccount.address, accounts);
        console.log("🚀 ~ handleDelegation ~ res:", result);
        setTxStatus(result.status);
        setShowToast(true);

        if (result.status) {
          setdelegate(true);
          setMessage(`${result.message}`);
        } else {
          setMessage(`${result.message}`);
          console.error("Delegate transaction failed:", result.message);
        }
      }
    } catch (error) {
      console.error("Error during delegation:", error);
      setMessage("An error occurred during delegation. Please try again.");
    } finally {
      setDelegateLoading(false);
    }
  };

  // User can select any address from connected addresses
  const handleWalletAddress = async (address: string) => {
    const matchAccount = accounts.find(
      (account) => account.address === address
    );
    if (matchAccount) {
      setMainAccount(
        matchAccount?.address,
        matchAccount.meta.name ? matchAccount.meta.name : ""
      );
    }
    await setUserBalance(address);
    setdelegate((await checkIfDelegated(address, target)).isDelegated);
  };

  const btnName = isDelegated ? "Delegated already" : "Approve Delegation";

  return (
    <div className="container">
      <h1 className="heading1 text-start">User Dashboard</h1>
      <div className="row align-items-stretch justify-content-evenly py-3">
        {!isConnected ? (
          <div className={`col-5 ${styles.connect}`}>
            <div className={`card text-center mt-3 p-3 ${styles.cardDetails}`}>
              <p>Connect your wallet</p>
              <button
                type="button"
                className={`btn btn-dark fw-medium trustedButton`}
                onClick={() => {
                  enableExtension();
                }}
              >
                Connect Your Wallet
              </button>
            </div>
          </div>
        ) : (
          <>
            <div
              className={`col-12 col-xl-7 card text-center p-3 ${styles.cardDetails}`}
            >
              <div
              // className={`card text-center mt-3 p-3 ${styles.cardDetails}`}
              >
                <>
                  <div className="card-header bg-transparent">
                    <ul className="nav nav-tabs card-header-tabs border border-0  ">
                      <li className="nav-item">
                        <a
                          className="nav-link active fw-bold"
                          aria-current="true"
                          href="/"
                        >
                          Wallet
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="card-body">
                    <h5
                      className={`card-title d-flex align-items-center justify-content-start `}
                    >
                      <p className="fw-normal fs-xl-5 fs-6">Address: </p>
                      <div className="dropdown">
                        <button
                          className="btn btn-light border-0 bg-transparent"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <p className="fs-xl-5 fs-6 ps-1 dropdown-toggle">
                            <span className="full-address d-none d-md-inline">
                              {selectedAccount.address}
                            </span>
                            <span className="short-address d-inline d-md-none">
                              <ShortenString str={selectedAccount.address} />
                            </span>
                          </p>
                        </button>
                        <ul className="dropdown-menu">
                          {accounts
                            .filter(
                              (account) =>
                                account.address !== selectedAccount.address
                            )
                            .map((account, index) => (
                              <li key={index}>
                                <button
                                  className="dropdown-item btn btn-light bg-transparent border-0"
                                  onClick={() =>
                                    handleWalletAddress(account.address)
                                  }
                                >
                                  {account.address}
                                </button>
                              </li>
                            ))}
                        </ul>
                      </div>
                    </h5>
                    <div className="card-text">
                      <Table responsive borderless striped>
                        <tbody className="text-start p-0">
                          <tr>
                            <td>Wallet Address: </td>
                            <td>
                              <ShortenString str={selectedAccount.address} />
                            </td>
                          </tr>
                          <tr>
                            <td>Wallet Name: </td>
                            <td>{selectedAccount.name}</td>
                          </tr>
                          <tr>
                            <td>Total Balance: </td>
                            <td>
                              τ{" "}
                              {(
                                walletOverview.free +
                                walletOverview.frozen +
                                walletOverview.reserved
                              ).toFixed(3)}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                    <div
                      className={`d-flex  justify-content-around ${styles.userinfo}`}
                    >
                      <Link to={"/delegates"}>
                        <button
                          type="button"
                          className={`btn btn-dark fw-medium trustedButton ${styles.navBtn}`}
                          onClick={
                            location.pathname === "/delegates"
                              ? async () => await handleDelegation()
                              : () => {}
                          }
                          disabled={
                            location.pathname === "/delegates" &&
                            (isDelegated || delegateLoading)
                          }
                        >
                          {delegateLoading ? (
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : location.pathname === "/delegates" ? (
                            btnName
                          ) : (
                            "Delegate Your Hotkey"
                          )}
                        </button>
                      </Link>

                      {location.pathname === "/delegates" && (
                        <button
                          type="button"
                          className={`trustedButton btn btn-dark fw-medium ${styles.navBtn}`}
                          onClick={async () => await handleUndelegation()}
                          disabled={!isDelegated || unDelegateloading}
                        >
                          {unDelegateloading ? (
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            "Undelegate"
                          )}
                        </button>
                      )}
                    </div>
                  </div>
                </>
              </div>
            </div>
            {stakedAmt ? (
              <div
                className={`col-4  card text-center p-3 ${styles.port}  ${styles.cardDetails}`}
              >
                <div>
                  <div className="card-header bg-transparent fw-bold">
                    Portfolio
                  </div>
                  <div className="card-title pt-4 pb-3 px-2 d-flex justify-content-lg-evenly">
                    <div className="">
                      <strong>Staked: </strong> {stakedAmt} τ
                    </div>
                    <div className="">
                      <strong>Free: </strong> {freeBalance} τ
                    </div>
                  </div>
                  <div className="card-body justify-content-center">
                    <UserPortfolio free={freeBalance} staked={stakedAmt} />
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {showToast && (
              <div className="toast-container position-absolute top-0 end-0 p-3">
                <div
                  className={`toast show ${
                    txStatus ? "bg-success" : "bg-danger"
                  }`}
                >
                  <TrustedToast
                    txStatus={txStatus!}
                    message={message ? message : ""}
                    onClose={() => setShowToast(false)} // Close the toast after 3 seconds
                  />
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default UserInfo;
