import React, { useContext } from "react";
import SubnetTable from "../../components/list/subnetList";
import { TaoContext } from "../../context/taoDetailsContext";

function Subnets() {
  const { subnetList } = useContext(TaoContext);
  return (
    <div className="container">
      {Object.keys(subnetList).length !== 0 ? (
        <>
          <p className="heading1 text-start mt-5">Bittensor Subnets</p>
          <SubnetTable data={subnetList} rowsToShow={subnetList.length} />
        </>
      ) : (
        ""
      )}
    </div>
  );
}

export default Subnets;
