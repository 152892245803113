import { SubmittableResult } from "@polkadot/api";
import api from "../wallet/polkadotApi";
import { createUser, getUserData, updateUser } from "./userbackend";
import { InjectedAccountWithMeta } from "@polkadot/extension-inject/types";
import { web3FromAddress, web3FromSource } from "@polkadot/extension-dapp";

const baseURL = `${process.env.REACT_APP_BASE_URL}`;

export const delegate = async (
  userAddress: string,
  ac: InjectedAccountWithMeta[]
) => {
  try {
    const matchingAccount = ac.find(
      (account) => account.address === userAddress
    );
    const signer = matchingAccount
      ? await web3FromSource(matchingAccount.meta.source)
      : await web3FromAddress(userAddress);
    (await api).setSigner(signer.signer);
    const target = `${process.env.REACT_APP_PROXY_ADDRESS}`;

    if (!(await checkIfDelegated(userAddress, target)).isDelegated) {
      const balance = await getBalanceOfUser(userAddress);
      if (balance.free < parseFloat(`${process.env.REACT_APP_MINIMUSTAKE}`)) {
        return { status: false, message: "Insufficient Wallet Balance" };
      }
      const proxyTx = (await api).tx.proxy.addProxy(target, "Staking", 0);

      const res = await new Promise<{ message: string; status: boolean }>(
        (resolve, reject) => {
          proxyTx
            .signAndSend(userAddress, (result: SubmittableResult) => {
              if (result.status.isFinalized) {
                console.log(
                  `Tx finalized in block ${result.status.asFinalized}`
                );
                resolve({
                  message: `${result.status.asFinalized}`,
                  status: true,
                });
              } else if (result.isError) {
                console.error("Error in transaction:", result);
                reject(new Error("Error in transaction"));
              }
            })
            .catch((error: Error) => {
              reject(error);
            });
        }
      );
      if (
        (await res).status &&
        (await checkIfDelegated(userAddress, target)).isDelegated
      ) {
        const balance = await getBalanceOfUser(userAddress);
        const currentUserDetailsResponse = await getUserData(userAddress);

        if (currentUserDetailsResponse.wallet_address) {
          const currentUserDetails = await currentUserDetailsResponse;

          // Make the PUT API call to update the is_delegated field
          const updateUserDelegation = await updateUser(
            currentUserDetails.wallet_address,
            parseFloat((balance.free / 1000000000).toFixed(2)),
            parseFloat(currentUserDetails.stake_balance),
            parseFloat(currentUserDetails.profit_loss),
            currentUserDetails.is_active,
            true
          );
          if (updateUserDelegation) {
            return { status: true, message: res.message };
          }
        }
        const add = await createUser(
          userAddress,
          parseFloat((balance.free / 1000000000).toFixed(2)),
          true,
          true
        );
        return { status: add, message: (await res).message };
      }
      return { status: false, message: "Something went wrong" };
    } else {
      return { status: false, message: "Already Delegated" };
    }
  } catch (error) {
    console.log("🚀 ~ delegate ~ error:", error);
    return { status: false, message: error };
  }
};

export const removeDelegate = async (
  userAddress: string,
  ac: InjectedAccountWithMeta[]
) => {
  try {
    const matchingAccount = ac.find(
      (account) => account.address === userAddress
    );
    const signer = matchingAccount
      ? await web3FromSource(matchingAccount.meta.source)
      : await web3FromAddress(userAddress);
    (await api).setSigner(signer.signer);

    const target = `${process.env.REACT_APP_PROXY_ADDRESS}`;

    // Check if the user is delegated
    if ((await checkIfDelegated(userAddress, target)).isDelegated) {
      // Create the transaction to remove the proxy (delegation)
      const proxyTx = (await api).tx.proxy.removeProxy(target, "Staking", 0);

      const res = await new Promise<{ message: string; status: boolean }>(
        (resolve, reject) => {
          proxyTx
            .signAndSend(userAddress, (result: SubmittableResult) => {
              if (result.status.isFinalized) {
                console.log(
                  `Tx finalized in block ${result.status.asFinalized}`
                );
                resolve({
                  message: `${result.status.asFinalized}`,
                  status: true,
                });
              } else if (result.isError) {
                console.error("Error in transaction:", result);
                reject(new Error("Error in transaction"));
              }
            })
            .catch((error: Error) => {
              reject(error);
            });
        }
      );

      // If transaction is successful, proceed to update delegation status
      if (res.status) {
        const updatedDelegationStatus = await checkIfDelegated(
          userAddress,
          target
        );

        // If delegation is removed successfully
        if (!updatedDelegationStatus.isDelegated) {
          // Fetch current user details
          const currentUserDetailsResponse = await getUserData(userAddress);

          if (!currentUserDetailsResponse) {
            throw new Error("Failed to fetch user details");
          }

          const currentUserDetails = await currentUserDetailsResponse;

          // Make the PUT API call to update the is_delegated field
          const updateUserDelegation = await updateUser(
            currentUserDetails.wallet_address,
            parseFloat(currentUserDetails.balance),
            parseFloat(currentUserDetails.stake_balance),
            parseFloat(currentUserDetails.profit_loss),
            currentUserDetails.is_active,
            false
          );

          // Check if the PUT request was successful
          if (updateUserDelegation) {
            return { status: true, message: res.message };
          } else {
            return {
              status: false,
              message: "Delegation removed but failed to update database",
            };
          }
        }
      }
      return { status: false, message: "Something went wrong" };
    } else {
      return { status: false, message: "Not a delegated address" };
    }
  } catch (error) {
    console.log("🚀 ~ removeDelegate ~ error:", error);
    return { status: false, message: error };
  }
};

export const getBalanceOfUser = async (addr: string) => {
  let data = JSON.parse(
    (await (await api).query.system.account(addr)).toString()
  );
  return data.data;
};

export const checkIfDelegated = async (
  userAddress: string,
  proxyAddress: string
) => {
  try {
    // Check if already delegated
    const totalProxies: any = (
      await (await api).query.proxy.proxies(userAddress)
    ).toHuman();

    let isDelegated = false;

    isDelegated =
      Array.isArray(totalProxies[0]) && totalProxies[0].length > 0
        ? totalProxies[0].some(
            (proxy: { delegate: string }) => proxy.delegate === proxyAddress
          )
        : false;

    return { isDelegated: isDelegated };
  } catch (error) {
    console.log("🚀 ~ checkIfDelegated ~ error:", error);
    return { isDelegated: false };
  }
};
