interface AddrProps {
  str: string;
}

export const ShortenString: React.FC<AddrProps> = ({ str }) => {
  const shorten = (fullStr: string) => {
    if (fullStr.length <= 10) {
      return fullStr; // No need to shorten if it's already short.
    }
    const start = fullStr.substring(0, 5); // Get the first 4 characters.
    const end = fullStr.substring(fullStr.length - 5); // Get the last 4 characters.
    return `${start}...${end}`; // Concatenate them with ellipsis.
  };

  return <span className=" fw-bold">{shorten(str)}</span>;
};
