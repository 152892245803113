// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: var(--roboto-mono);
  background-color: rgba(0, 0, 0, 0);
  font-weight: 400;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.heading1 {
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.2;
}

.boxShadow {
  box-shadow: 0 0 10px #dddddd, inset 0 -2px 10px #fff6, inset 0 3px 0 #ffffff4d;
  border: none;
  background-color: white;
}

.trustedButton {
  border-radius: 0px !important;
  color: white !important;
  text-align: center !important;
  padding: 0.75rem 1.5rem !important;
  text-decoration: none;
  transition: all 0.2s !important;
  min-width: 160.87px;
}
.trustedButton:hover {
  transform: scale(0.94);
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;EAClC,+BAA+B;EAC/B,kCAAkC;EAClC,gBAAgB;AAClB;;AAEA;EACE;aACW;AACb;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,8EAA8E;EAC9E,YAAY;EACZ,uBAAuB;AACzB;;AAEA;EACE,6BAA6B;EAC7B,uBAAuB;EACvB,6BAA6B;EAC7B,kCAAkC;EAClC,qBAAqB;EACrB,+BAA+B;EAC/B,mBAAmB;AACrB;AACA;EACE,sBAAsB;AACxB","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\",\n    \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\",\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  font-family: var(--roboto-mono);\n  background-color: rgba(0, 0, 0, 0);\n  font-weight: 400;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\n    monospace;\n}\n\n.heading1 {\n  font-size: 3rem;\n  font-weight: 700;\n  line-height: 1.2;\n}\n\n.boxShadow {\n  box-shadow: 0 0 10px #dddddd, inset 0 -2px 10px #fff6, inset 0 3px 0 #ffffff4d;\n  border: none;\n  background-color: white;\n}\n\n.trustedButton {\n  border-radius: 0px !important;\n  color: white !important;\n  text-align: center !important;\n  padding: 0.75rem 1.5rem !important;\n  text-decoration: none;\n  transition: all 0.2s !important;\n  min-width: 160.87px;\n}\n.trustedButton:hover {\n  transform: scale(0.94);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
